/**
 * Inview plugin
 * - check for vertical only
 */
export default class {
  constructor(options = {}, noGlobalEvt = false) {
    this.options = {
      target: 'inview',
      appendClass: '__in',
      offset: 0,
      enablePersist: true,
      delay: 300,
      ...options,
    };
    this.evt = new Event('inview');
    this.ticking = false;
    this.items = {};
    this.uid = 0;
    this.init();
    if (!noGlobalEvt) {
      this.initGlobal();
      // init checking
      setTimeout(() => {
        this.viewportCheck();
      }, 200);
    }
  }

  /**
   * Initialize global events
   */
  initGlobal() {
    // create global event instance
    window.addEventListener('scroll', () => {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.viewportCheck();
          this.ticking = false;
        });
      }
      this.ticking = true;
    }, { passive: true });
  }

  /**
   * Initialize elements
   */
  init() {
    const els = document.getElementsByClassName(this.options.target);
    Array.from(els).forEach((el) => {
      this.addItem(el);
    });
  }

  /**
   * Check elements viewport
   */
  viewportCheck() {
    clearTimeout(this.chkTimer);
    this.chkTimer = setTimeout(() => {
      Object.values(this.items).forEach((item) => {
        this.viewportCheckEach(item);
      });
    }, this.options.delay);
  }

  /**
   * Handle what to do for each elements
   *
   * @param DOM el
   */
  viewportCheckEach(el) {
    const isIn = this.isInViewport(el);
    // once time check
    if (isIn && (el.dataset.once || !this.options.enablePersist)) {
      el.classList.add(this.options.appendClass);
      el.dispatchEvent(this.evt);
      this.removeItem(el);
      return;
    }

    // persist-case
    if (isIn && !el.classList.contains(this.options.appendClass)) {
      el.classList.add(this.options.appendClass);
    } else if (!isIn && el.classList.contains(this.options.appendClass)) {
      el.classList.remove(this.options.appendClass);
    }
  }

  /**
   * Check element is inside viewport
   *
   * @param DOM el - DOM element
   * @return boolean
   */
  isInViewport(el) {
    const rect = el.getBoundingClientRect();
    const offset = el.dataset.offset ? Number(el.dataset.offset) : this.options.offset;
    const h = (window.innerHeight || document.documentElement.clientHeight) - offset;

    return (rect.top >= offset && rect.top <= h)
      || (rect.bottom >= offset && rect.bottom <= h);
  }

  /**
   * Add element into check list
   *
   * @param DOM el
   */
  addItem(el) {
    el.dataset.inviewId = this.uid;
    this.items[this.uid] = el;
    this.uid++;
  }

  /**
   * Remove element from check list
   *
   * @param DOM el
   */
  removeItem(el) {
    if (el.dataset.inviewId) {
      delete this.items[el.dataset.inviewId];
    }
  }

}
