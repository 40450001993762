export default class {
  constructor(el, options = null) {
    this.el = el;
    this.options = {
      autoplay: 1,
      mute: 1,
      showinfo: 0,
      controls: 0,
      rel: 0,
      fs: 0,
      modestbranding: 1,
      loop: 1,
      disablekb: 1,
      playsinline: 1,
      iv_load_policy: 3,
      cc_load_policy: 0,
      enablejsapi: 1,
      origin: window.location.origin,
      ...options,
    };

    this.player = null;
    this.$el = document.getElementById(el);
    this.vid = this.$el.dataset.videoId;
    this.rsTimer = null;
    if (this.$el && this.vid) {
      this.initYoutubeApi();
      this.initIFrame();
    }
  }

  /**
   * Initialize iframe
   */
  initIFrame() {
    this.$el.src = this.generateIframeUrl();
    this.resizeIFrame();

    this.resizeEvt = this.onResize.bind(this);
    window.addEventListener('resize', this.resizeEvt);
  }

  /**
   * Initialize YT API script
   */
  initYoutubeApi() {
    // skip init if already done
    const scriptKey = 'ytbgScript';
    if (document.getElementById(scriptKey)) {
      return this.initPlayer();
    }

    const tag = document.createElement('script');
    tag.id = scriptKey;
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window.onYouTubeIframeAPIReady = () => {
      this.initPlayer();
    };
  }

  /**
   * Initialize YT Player
   */
  initPlayer() {
    this.player = new YT.Player(this.el, {
      events: {
        onReady: (event) => this.onPlayerReady(event),
        onStateChange: (event) => this.onStateChange(event),
        onError: (err) => this.onError(err),
      }
    });
  }

  /**
   * Generate YT iframe URL
   */
  generateIframeUrl() {
    const qs = new URLSearchParams(this.options).toString();
    return `https://www.youtube.com/embed/${this.vid}?${qs}`;
  }

  /**
   * Resize iFrame
   */
  resizeIFrame() {
    const $parent = this.$el.parentNode;
    const scale = Math.ceil(Math.max(
      $parent.offsetWidth / this.$el.offsetWidth,
      $parent.offsetHeight / this.$el.offsetHeight,
    ) * 100) / 100;
    this.$el.style.position = 'absolute';
    this.$el.style.top = '50%';
    this.$el.style.left = '50%';
    this.$el.style.transform = `translate(-50%, -50%) scale(${scale})`;
  }

  /**
   * Video inited
   */
  onPlayerReady() {
    // console.log('ready??');
    // autoplay
    if (this.options.autoplay) {
      this.player.playVideo();
    }
  }

  /**
   * Video state changed
   */
  onStateChange(event) {
    if (event.data === -1 && this.options.loop) {
      this.player.playVideo();
    }
    if (event.data === 0 && this.options.loop) {
      this.player.playVideo();
    }
    if (event.data === 1) {
      this.$el.classList.add('ready');
    }
  }

  /**
   * Window resized
   */
  onResize() {
    clearTimeout(this.rsTimer);
    this.rsTimer = setTimeout(() => {
      this.resizeIFrame();
    }, 200);
  }

  /**
   * Destroy
   */
  destroy() {
    clearTimeout(this.rsTimer);
    window.removeEventListener('resize', this.resizeEvt);
  }
}