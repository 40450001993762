import Swiper, { Pagination, Autoplay } from 'swiper';
import YoutubeBgClass from './youtube-bg';
import InviewClass from './inview';

class App {
  constructor() {
    Swiper.use([Pagination, Autoplay]);

    this.isMobile = window.matchMedia('only screen and (max-width: 576px)').matches;
    this.scrollEvt = [];
    this.resizeEvt = [];
    this.parallaxItems = [];

    this.initInview();
    this.initToggle();
    this.initParallax();
    this.initHeader();
    this.initHero();
    this.initGallery();
    this.initTestimonial();
    this.initFaq();
    // this.initAutoscroll();
    this.initEvents();
    this.initPetals();
    this.initContact();
  }

  initInview() {
    const inview = new InviewClass({
      target: 'inview',
      appendClass: '__in',
      offset: 0,
      delay: 50,
      enablePersist: !this.isMobile,
    }, true);

    // since global is off, hence need manually re-trigger init checking
    setTimeout(() => {
      inview.viewportCheck();
    }, 200);

    this.scrollEvt.push(() => inview.viewportCheck());
  }

  initToggle() {
    const $nodes = document.querySelectorAll('[data-toggle="class"]');
    let $cur = null;
    function windowEvt(e) {
      if ($cur && !$cur.parentNode.contains(e.target)) {
        $cur.click();
      }
    }
    Array.from($nodes).forEach(($node) => {
      $node.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        $cur = e.currentTarget;
        const cls = $cur.dataset.class || 'shown';
        const globalEvt = $cur.dataset.globalClose || false;
        const $tar = document.getElementById(
          ($node.dataset.target || $node.getAttribute('href') || '').replace('#', '')
        );
        // show
        if (!$tar.classList.contains(cls)) {
          $cur.classList.add('toggled');
          $tar.classList.add(cls);
          setTimeout(() => {
            $tar.classList.toggle('__in');
          }, 100);
          // create close event
          if (globalEvt) {
            window.addEventListener('click', windowEvt);
          }
        }
        // hide
        else {
          $cur.classList.remove('toggled');
          $tar.classList.remove(cls, '__in');
          if (globalEvt) {
            window.removeEventListener('click', windowEvt);
          }
        }
      });
    });
  }

  initParallax() {
    // disable on mobile
    if (this.isMobile) {
      return;
    }

    const $els = document.querySelectorAll('[data-parallax]');
    Array.from($els).forEach(($el) => {
      this.parallaxItems.push($el);
    });

    function parallax(e) {
      if (!this.parallaxItems.length) {
        return;
      }
      this.parallaxItems.forEach(($el) => {
        // apply when inview only
        const rect = $el.getBoundingClientRect();
        const h = (window.innerHeight || document.documentElement.clientHeight);
        if ((rect.top >= 0 && rect.top <= h)
          || (rect.bottom >= 0 && rect.bottom <= h)) {
          const offset = $el.dataset.parallax || 1;
          const x = (e.clientX - (window.innerWidth / 2)) * (offset / 100);
          const y = (e.clientY - (window.innerHeight / 2)) * (offset / 100);
          $el.style.transform = `translate(${x}px, ${y}px)`;
        }
      });
    }
    document.addEventListener("mousemove", parallax.bind(this));
  }

  initHeader() {
    function getOffsetTop(element) {
      if (!element) return 0;
      return getOffsetTop(element.offsetParent) + element.offsetTop;
    };

    // menu
    const $el = document.getElementById('Nav');
    const $navs = $el.getElementsByClassName('navigation-link');
    Array.from($navs).forEach(($nav) => {
      const $child = $nav.children[0];
      $child.addEventListener('click', (e) => {
        e.preventDefault();
        const $tar = document.getElementById($child.getAttribute('href').replace('#', ''));
        if ($tar) {
          $el.classList.remove('active', '__in');
          window.scroll({
            top: getOffsetTop($tar),
            behavior: 'smooth',
          });
        }
      });
    });

    // sticky header
    function scrollCheck() {
      const scrollTop = document.documentElement.scrollTop;
      const $header = document.getElementById('Header');
      const $top = document.getElementById('ToTop');
      const heroHeight = document.getElementById('Hero').parentNode.offsetHeight;
      if (scrollTop >= heroHeight) {
        if (!$header.classList.contains('sticky')) {
          $header.classList.add('sticky');
          window.requestAnimationFrame(() => {
            $header.classList.add('__in');
          });
        }
        if (!$top.classList.contains('active')) {
          $top.classList.add('active');
        }
      } else {
        if ($header.classList.contains('sticky')) {
          $header.classList.remove('sticky', '__in');
        }
        if ($top.classList.contains('active')) {
          $top.classList.remove('active');
        }
      }
    }
    this.scrollEvt.push(scrollCheck);
  }

  initHero() {
    // video
    new YoutubeBgClass('ytBg');
  
    // scroll efx
    function scrollCheck() {
      const scrollTop = document.documentElement.scrollTop;
      const $hero = document.getElementById('Hero');
      if (scrollTop > 50) {
        if (!$hero.classList.contains('shown')) {
          $hero.classList.add('shown');
        }
      } else {
        if ($hero.classList.contains('shown')) {
          $hero.classList.remove('shown');
        }
      }
    }
    this.scrollEvt.push(scrollCheck);
  }

  initGallery() {
    const $el = document.getElementById('GallerySlider');
    const $thumbs = $el.getElementsByClassName('thumb');

    Array.from($thumbs).forEach((dom) => {
      dom.addEventListener('click', (e) => {
        e.preventDefault();
        const $btn = e.currentTarget;
        const $tar = document.getElementById($btn.getAttribute('href').replace('#', ''));
        const $cur = $el.getElementsByClassName('gallery-item active')[0];
        const $curBtn = $el.getElementsByClassName('thumb active')[0];
        
        // current view
        if ($cur) {
          $cur.classList.remove('active');
          // pause video (Youtube only)
          if ($cur.dataset.type === 'video') {
            const $yt = $cur.getElementsByTagName('iframe')[0];
            $yt.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
          }
        }
        // current btn
        if ($curBtn) {
          $curBtn.classList.remove('active');
        }
        // target view
        if ($tar) {
          $tar.classList.add('active');
        }
        // target btn
        $btn.classList.add('active');
      });
    });
  }

  initTestimonial() {
    // swiper
    const swiper = new Swiper('#Swiper', {
      slidesPerView: 'auto',
      centeredSlides: true,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    // events
    const $el = document.getElementById('Testimonials');
    const $btns = $el.getElementsByClassName('testimonial-more');
    Array.from($btns).forEach(($node) => {
      $node.addEventListener('click', (e) => {
        e.preventDefault();
        const $btn = e.currentTarget;
        const $parent = $btn.closest('.testimonial');
        const $txt = $btn.getElementsByClassName('more')[0];
        if ($parent) {
          $parent.classList.toggle('hovered');
        }
        if ($txt) {
          $txt.textContent = $parent.classList.contains('hovered') ? $btn.dataset.txtHide : $btn.dataset.txtMore;
        }
      });
    });
  }

  initFaq() {
    const $el = document.getElementById('FaqBox');
    const $togglers = $el.getElementsByClassName('faq-heading');

    function init() {
      const $nodes = $el.getElementsByClassName('faq');
      Array.from($nodes).forEach(($node) => {
        $node.classList.remove('collapsed');
        const $body = $node.getElementsByClassName('faq-body')[0];
        if ($body) {
          $body.removeAttribute('style');
          $body.style.height = `${$body.offsetHeight}px`;
        }
        $node.classList.add('collapsed');
      });
    }
    init();
    this.resizeEvt.push(init);
    
    // create events
    Array.from($togglers).forEach(($node) => {
      $node.addEventListener('click', (e) => {
        const $parent = e.currentTarget.parentNode;
        if ($parent.classList.contains('faq')) {
          $parent.classList.toggle('collapsed');
        }
      });
    });
  }

  initEvents() {
    let ticking = false;
    window.addEventListener('scroll', () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.scrollEvt.forEach((evt) => evt());
          ticking = false;
        });
      }
      ticking = true;
    }, { passive: true });

    let timer = null;
    window.addEventListener('resize', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.resizeEvt.forEach((evt) => evt());
      }, 500);
    });
  }

  initPetals() {
    if (this.isMobile) {
      return;
    }
    
    // disable on mobile
    const $el = document.getElementById('Petals');
    const $el2 = document.getElementById('Petals2');
    const $nodes = [];
    Array.from($el.childNodes).forEach(($dom) => {
      if ($dom.tagName === 'path') {
        $nodes.push($dom);
      }
    });
    Array.from($el2.childNodes).forEach(($dom) => {
      if ($dom.tagName === 'path') {
        $nodes.push($dom);
      }
    });
    function check() {
      const h = (window.innerHeight || document.documentElement.clientHeight) / 3 * 2;
      $nodes.forEach(($dom) => {
        const rect = $dom.getBoundingClientRect();
        if (rect.top <= h) {
          $dom.classList.add('__in');
        } else {
          $dom.classList.remove('__in');
        }
      });
    }
    this.scrollEvt.push(check);
  }

  initAutoscroll() {
    // disable on mobile
    if (this.isMobile) {
      return;
    }

    const checkList = {};
    const $nodes = document.querySelectorAll('[data-scroll="auto"]');

    function getOffsetTop(element) {
      if (!element) return 0;
      return getOffsetTop(element.offsetParent) + element.offsetTop;
    };
    
    function generateCheckList() {
      const h = window.innerHeight || document.documentElement.clientHeight;
      Array.from($nodes).forEach(($node) => {
        if ($node.id) {
          const t = getOffsetTop($node);
          checkList[$node.id] = [t - (h / 2), t + (h / 2), t];
        }
      });
    }
    generateCheckList();
    this.resizeEvt.push(generateCheckList);

    let timer = null;
    function scrollCheck() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const scrollTop = document.documentElement.scrollTop;
        const found = Object.keys(checkList).find((id) => {
          return (scrollTop > checkList[id][0] && scrollTop < checkList[id][1]);
        });
        if (found) {
          window.scroll({
            top: checkList[found][2],
            behavior: 'smooth',
          });
        }
      }, 50);
    }
    this.scrollEvt.push(scrollCheck);
  }

  initContact() {
    const $form = document.getElementById('ContactForm');
    const $modal = document.getElementById('ContactModal');
    const $btn = $form.querySelector('button[type="submit"]');
    if (!$form) {
      return;
    }

    $form.addEventListener('submit', (e) => {
      e.preventDefault();
      // set state
      $btn.disabled = true;

      // old school xhr xD
      const formData = new FormData($form);
      const xhr = new XMLHttpRequest();
      xhr.open('POST', $form.getAttribute('action'));
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // const json = JSON.parse(xhr.response);
          $modal.classList.add('shown');
          setTimeout(() => {
            $modal.classList.add('__in');
          }, 100);

          $form.reset();
          $btn.disabled = false;
        }
      };
      xhr.send(formData);
    });

    const $nodes = $modal.querySelectorAll('[data-close]');
    Array.from($nodes).forEach(($node) => {
      $node.addEventListener('click', () => {
        $modal.classList.remove('shown', '__in');
      });
    });
  }
}

new App();
